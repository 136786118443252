import { env } from '@/env'
import { signupAtom } from '@/lib/atoms'
import { createBuyLink } from '@/lib/utils'
import { getUserOrg } from '@/pages/api/functions'
import { useSession } from 'next-auth/react'
import { useState, useEffect, useRef } from 'react'
let keyPrefix = 'saved-state-'

let img = require('@/../public/og_image.png')
export const ogImageUrl = new URL(
    img.src || img?.default.src,
    env.NEXT_PUBLIC_URL,
).toString()

export let faviconUrl =
    'https://image-forwarder.notaku.so/aHR0cHM6Ly9ub3Rpb24tdGFza3MtYzc2NWM4ZS1oaGxqM2k2ZWlxLXVlLmEucnVuLmFwcC9lbW9qaS8lRjAlOUYlQTQlOTY='

export function useBuy() {
    const { data: session } = useSession()
    async function buyPlan(e) {
        if (!session) {
            signupAtom.set(true)
            return
        }
        const org = await getUserOrg({})
        window.location.href = createBuyLink({
            email: session?.user?.email,
            orgId: org?.id,
        })
    }
    return buyPlan
}

function safeJsonParse(json) {
    try {
        return JSON.parse(json)
    } catch (e) {
        return null
    }
}

export function usePersistentState(key, defaultValue) {
    key = keyPrefix + key
    const [value, setValue] = useState(defaultValue)
    useEffect(() => {
        const saved = localStorage.getItem(key)
        if (saved) {
            let parsed = safeJsonParse(saved)
            if (parsed) {
                // console.log('restoring saved state for ' + key, parsed)
                setValue(parsed)
            }
        }
    }, [])

    useDebouncedEffect(
        () => {
            try {
                localStorage.setItem(key, JSON.stringify(value))
            } catch (e) {}
        },
        [value],
        200,
    )
    return [value, setValue]
}

export function useDebouncedEffect(callback, deps = [], delay = 120) {
    const data = useRef({ firstTime: true, clearFunc: null })
    useEffect(() => {
        const { firstTime, clearFunc } = data.current

        if (firstTime) {
            data.current.firstTime = false
            return
        }

        const handler = setTimeout(() => {
            if (clearFunc && typeof clearFunc === 'function') {
                clearFunc()
            }
            data.current.clearFunc = callback()
        }, delay)

        return () => {
            clearTimeout(handler)
        }
    }, [delay, ...deps])
}

export function useMounted() {
    const [mounted, setMounted] = useState(false)
    useEffect(() => {
        setMounted(true)
    }, [])
    return mounted
}
