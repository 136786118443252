import { className, css, fonts, } from './chunk-E2SR4DPI.js';

// https :https://framerusercontent.com/modules/1gobdaiETEZYApPHkFW8/RAQ43YyjZfCUTli605Pd/j1CXYH1vu.js
import { jsx as _jsx, jsxs as _jsxs, } from 'react/jsx-runtime';
import {
  addFonts,
  addPropertyControls,
  ControlType,
  cx,
  Image,
  Link,
  RichText,
  useActiveVariantCallback,
  useComponentViewport,
  useLocaleInfo,
  useVariantState,
  withCSS,
} from 'unframer';
import { LayoutGroup, motion, MotionConfigContext, } from 'unframer';
import * as React from 'react';

// https :https://framerusercontent.com/modules/irtADPc9MsWpaOZ5xWct/Cqtyndb7HgebXowgH7mP/tr0U97vbW.js
import { fontStore, } from 'unframer';
fontStore.loadWebFontsFromSelectors(['GF;DM Sans-500',],);
var fonts2 = [{
  family: 'DM Sans',
  moduleAsset: {
    localModuleIdentifier: 'local-module:css/tr0U97vbW:default',
    url: 'https://fonts.gstatic.com/s/dmsans/v11/rP2Cp2ywxg089UriAWCrOB-sClQX6Cg.ttf',
  },
  style: 'normal',
  url: 'https://fonts.gstatic.com/s/dmsans/v11/rP2Cp2ywxg089UriAWCrOB-sClQX6Cg.ttf',
  weight: '500',
},];
var css2 = [
  '.framer-lY2DH .framer-styles-preset-1ege25p:not(.rich-text-wrapper), .framer-lY2DH .framer-styles-preset-1ege25p.rich-text-wrapper p { --framer-font-family: "DM Sans", sans-serif; --framer-font-size: 16px; --framer-font-style: normal; --framer-font-weight: 500; --framer-letter-spacing: -0.2px; --framer-line-height: 26px; --framer-paragraph-spacing: 20px; --framer-text-alignment: start; --framer-text-color: #ffffff; --framer-text-decoration: none; --framer-text-transform: none; }',
];
var className2 = 'framer-lY2DH';

// https :https://framerusercontent.com/modules/1gobdaiETEZYApPHkFW8/RAQ43YyjZfCUTli605Pd/j1CXYH1vu.js
var enabledGestures = { BM6EYWPbP: { hover: true, }, MHKwcgpiE: { hover: true, }, nvj6GRWIN: { hover: true, }, };
var cycleOrder = ['nvj6GRWIN', 'BM6EYWPbP', 'MHKwcgpiE',];
var serializationHash = 'framer-CxqYs';
var variantClassNames = { BM6EYWPbP: 'framer-v-1vkpx8j', MHKwcgpiE: 'framer-v-1fbf01a', nvj6GRWIN: 'framer-v-1skui0i', };
function addPropertyOverrides(overrides, ...variants) {
  const nextOverrides = {};
  variants === null || variants === void 0
    ? void 0
    : variants.forEach((variant,) => variant && Object.assign(nextOverrides, overrides[variant],));
  return nextOverrides;
}
var transitions = { default: { damping: 60, delay: 0, mass: 1, stiffness: 500, type: 'spring', }, };
var toResponsiveImage = (value,) => {
  if (typeof value === 'object' && value !== null && typeof value.src === 'string') {
    return value;
  }
  return typeof value === 'string' ? { src: value, } : void 0;
};
var Transition = ({ value, children, },) => {
  const config = React.useContext(MotionConfigContext,);
  const transition = value !== null && value !== void 0 ? value : config.transition;
  const contextValue = React.useMemo(() => ({ ...config, transition, }), [JSON.stringify(transition,),],);
  return /* @__PURE__ */ _jsx(MotionConfigContext.Provider, { value: contextValue, children, },);
};
var Variants = motion(React.Fragment,);
var humanReadableVariantMap = { 'Primary Smaller': 'MHKwcgpiE', Primary: 'nvj6GRWIN', Secondary: 'BM6EYWPbP', };
var getProps = ({ height, id, leftIcon, link, rightIcon, showLeftIcon, showRightIcon, tap, title, width, ...props },) => {
  var _ref, _ref1, _ref2, _humanReadableVariantMap_props_variant, _ref3, _ref4;
  return {
    ...props,
    BQ8KjTU41: (_ref = rightIcon !== null && rightIcon !== void 0 ? rightIcon : props.BQ8KjTU41) !== null && _ref !== void 0
      ? _ref
      : { src: 'https://framerusercontent.com/images/QDgxMHJz2vKvFSMvhZpA6xplIBM.svg', },
    FOdt_Q22J: (_ref1 = title !== null && title !== void 0 ? title : props.FOdt_Q22J) !== null && _ref1 !== void 0
      ? _ref1
      : 'Get started now',
    gT8MXhd7P: (_ref2 = leftIcon !== null && leftIcon !== void 0 ? leftIcon : props.gT8MXhd7P) !== null && _ref2 !== void 0
      ? _ref2
      : { src: 'https://framerusercontent.com/images/QDgxMHJz2vKvFSMvhZpA6xplIBM.svg', },
    HihQ7sLzJ: tap !== null && tap !== void 0 ? tap : props.HihQ7sLzJ,
    JRpxGFjwH: showLeftIcon !== null && showLeftIcon !== void 0 ? showLeftIcon : props.JRpxGFjwH,
    variant:
      (_ref3 =
            (_humanReadableVariantMap_props_variant = humanReadableVariantMap[props.variant]) !== null &&
              _humanReadableVariantMap_props_variant !== void 0
              ? _humanReadableVariantMap_props_variant
              : props.variant) !== null && _ref3 !== void 0
        ? _ref3
        : 'nvj6GRWIN',
    wsxRD5u41: (_ref4 = showRightIcon !== null && showRightIcon !== void 0 ? showRightIcon : props.wsxRD5u41) !== null && _ref4 !== void 0
      ? _ref4
      : true,
    Xf0re2Pc_: link !== null && link !== void 0 ? link : props.Xf0re2Pc_,
  };
};
var createLayoutDependency = (props, variants,) => variants.join('-',) + props.layoutDependency;
var Component = /* @__PURE__ */ React.forwardRef(function (props, ref,) {
  const { activeLocale, setLocale, } = useLocaleInfo();
  const {
    style,
    className: className3,
    layoutId,
    variant,
    FOdt_Q22J,
    BQ8KjTU41,
    gT8MXhd7P,
    JRpxGFjwH,
    wsxRD5u41,
    Xf0re2Pc_,
    HihQ7sLzJ,
    ...restProps
  } = getProps(props,);
  const { baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants, } = useVariantState({
    cycleOrder,
    defaultVariant: 'nvj6GRWIN',
    enabledGestures,
    transitions,
    variant,
    variantClassNames,
  },);
  const layoutDependency = createLayoutDependency(props, variants,);
  const { activeVariantCallback, delay, } = useActiveVariantCallback(baseVariant,);
  const onTapw8vc2x = activeVariantCallback(async (...args) => {
    setGestureState({ isPressed: false, },);
    if (HihQ7sLzJ) {
      const res = await HihQ7sLzJ(...args,);
      if (res === false) {
        return false;
      }
    }
  },);
  const ref1 = React.useRef(null,);
  const defaultLayoutId = React.useId();
  const sharedStyleClassNames = [className2, className,];
  const componentViewport = useComponentViewport();
  return /* @__PURE__ */ _jsx(LayoutGroup, {
    id: layoutId !== null && layoutId !== void 0 ? layoutId : defaultLayoutId,
    children: /* @__PURE__ */ _jsx(Variants, {
      animate: variants,
      initial: false,
      children: /* @__PURE__ */ _jsx(Transition, {
        value: transition,
        children: /* @__PURE__ */ _jsx(Link, {
          href: Xf0re2Pc_,
          openInNewTab: false,
          smoothScroll: true,
          children: /* @__PURE__ */ _jsx(motion.a, {
            ...restProps,
            className: `${cx(serializationHash, ...sharedStyleClassNames, 'framer-1skui0i', className3, classNames,)} framer-1oe6d9m`,
            'data-framer-name': 'Primary',
            'data-highlight': true,
            layoutDependency,
            layoutId: 'nvj6GRWIN',
            onHoverEnd: () => setGestureState({ isHovered: false, },),
            onHoverStart: () => setGestureState({ isHovered: true, },),
            onTap: onTapw8vc2x,
            onTapCancel: () => setGestureState({ isPressed: false, },),
            onTapStart: () => setGestureState({ isPressed: true, },),
            ref: ref !== null && ref !== void 0 ? ref : ref1,
            style: {
              '--border-bottom-width': '0px',
              '--border-color': 'rgba(0, 0, 0, 0)',
              '--border-left-width': '0px',
              '--border-right-width': '0px',
              '--border-style': 'solid',
              '--border-top-width': '0px',
              backgroundColor: 'var(--token-64603892-5c8b-477a-82d6-e795e75dd5dc, rgb(12, 64, 233))',
              borderBottomLeftRadius: 100,
              borderBottomRightRadius: 100,
              borderTopLeftRadius: 100,
              borderTopRightRadius: 100,
              ...style,
            },
            variants: {
              'BM6EYWPbP-hover': { '--border-color': 'rgba(0, 0, 0, 0.32)', },
              'MHKwcgpiE-hover': { backgroundColor: 'rgb(0, 0, 0)', },
              'nvj6GRWIN-hover': { backgroundColor: 'var(--token-64603892-5c8b-477a-82d6-e795e75dd5dc, rgb(255, 79, 0))', },
              BM6EYWPbP: {
                '--border-bottom-width': '1px',
                '--border-color': 'var(--token-19c4737c-eef7-4396-b22a-028772ac270e, rgba(0, 0, 0, 0.1)) /* {"name":"Outline"} */',
                '--border-left-width': '1px',
                '--border-right-width': '1px',
                '--border-style': 'solid',
                '--border-top-width': '1px',
                backgroundColor: 'var(--token-67c1333b-4249-4ff1-a333-3581964020b4, rgb(255, 255, 255))',
              },
            },
            ...addPropertyOverrides(
              {
                'BM6EYWPbP-hover': { 'data-framer-name': void 0, },
                'MHKwcgpiE-hover': { 'data-framer-name': void 0, },
                'nvj6GRWIN-hover': { 'data-framer-name': void 0, },
                BM6EYWPbP: { 'data-border': true, 'data-framer-name': 'Secondary', },
                MHKwcgpiE: { 'data-framer-name': 'Primary Smaller', },
              },
              baseVariant,
              gestureVariant,
            ),
            children: /* @__PURE__ */ _jsxs(motion.div, {
              className: 'framer-1n0h1yt',
              'data-framer-name': 'Button Content',
              layoutDependency,
              layoutId: 'I1:843;54:6377',
              style: { borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, },
              children: [
                JRpxGFjwH && /* @__PURE__ */
                _jsx(Image, {
                  background: {
                    alt: '',
                    fit: 'fill',
                    intrinsicHeight: 9,
                    intrinsicWidth: 9,
                    pixelHeight: 18,
                    pixelWidth: 18,
                    sizes: '18px',
                    ...toResponsiveImage(gT8MXhd7P,),
                  },
                  className: 'framer-12eesxg',
                  'data-framer-name': 'Left Icon',
                  layoutDependency,
                  layoutId: 'k5dhxG_In',
                },),
                /* @__PURE__ */ _jsx(RichText, {
                  __fromCanvasComponent: true,
                  children: /* @__PURE__ */ _jsx(React.Fragment, {
                    children: /* @__PURE__ */ _jsx(motion.p, {
                      className: 'framer-styles-preset-1ege25p',
                      'data-styles-preset': 'tr0U97vbW',
                      style: { '--framer-text-color': 'var(--extracted-r6o4lv, rgb(255, 255, 255))', },
                      children: 'Get started now',
                    },),
                  },),
                  className: 'framer-quibww',
                  'data-framer-name': 'Button Text',
                  layoutDependency,
                  layoutId: 'I1:843;54:6378',
                  style: { '--extracted-r6o4lv': 'rgb(255, 255, 255)', },
                  text: FOdt_Q22J,
                  variants: { BM6EYWPbP: { '--extracted-r6o4lv': 'var(--token-c97db126-8e49-4dfa-8a8a-566d4fa3e425, rgb(0, 0, 0))', }, },
                  verticalAlignment: 'top',
                  withExternalLayout: true,
                  ...addPropertyOverrides(
                    {
                      BM6EYWPbP: {
                        children: /* @__PURE__ */ _jsx(React.Fragment, {
                          children: /* @__PURE__ */ _jsx(motion.p, {
                            className: 'framer-styles-preset-1ege25p',
                            'data-styles-preset': 'tr0U97vbW',
                            style: {
                              '--framer-text-color':
                                'var(--extracted-r6o4lv, var(--token-c97db126-8e49-4dfa-8a8a-566d4fa3e425, rgb(0, 0, 0)))',
                            },
                            children: 'Get started now',
                          },),
                        },),
                      },
                      MHKwcgpiE: {
                        children: /* @__PURE__ */ _jsx(React.Fragment, {
                          children: /* @__PURE__ */ _jsx(motion.p, {
                            className: 'framer-styles-preset-tdptso',
                            'data-styles-preset': 'Q4kC2bTJ2',
                            style: { '--framer-text-color': 'var(--extracted-r6o4lv, rgb(255, 255, 255))', },
                            children: 'Get started now',
                          },),
                        },),
                      },
                    },
                    baseVariant,
                    gestureVariant,
                  ),
                },),
                wsxRD5u41 && /* @__PURE__ */ _jsx(Image, {
                  background: {
                    alt: '',
                    fit: 'fill',
                    intrinsicHeight: 9,
                    intrinsicWidth: 9,
                    pixelHeight: 18,
                    pixelWidth: 18,
                    sizes: '18px',
                    ...toResponsiveImage(BQ8KjTU41,),
                  },
                  className: 'framer-1czno15',
                  'data-framer-name': 'Left Icon',
                  layoutDependency,
                  layoutId: 'rwWGdWNHS',
                  style: {
                    borderBottomLeftRadius: 100,
                    borderBottomRightRadius: 100,
                    borderTopLeftRadius: 100,
                    borderTopRightRadius: 100,
                  },
                  ...addPropertyOverrides(
                    {
                      MHKwcgpiE: {
                        background: {
                          alt: '',
                          fit: 'fill',
                          intrinsicHeight: 9,
                          intrinsicWidth: 9,
                          pixelHeight: 18,
                          pixelWidth: 18,
                          sizes: '14px',
                          ...toResponsiveImage(BQ8KjTU41,),
                        },
                      },
                    },
                    baseVariant,
                    gestureVariant,
                  ),
                },),
              ],
            },),
          },),
        },),
      },),
    },),
  },);
},);
var css3 = [
  '.framer-CxqYs[data-border="true"]::after, .framer-CxqYs [data-border="true"]::after { content: ""; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }',
  '@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }',
  '.framer-CxqYs.framer-1oe6d9m, .framer-CxqYs .framer-1oe6d9m { display: block; }',
  '.framer-CxqYs.framer-1skui0i { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 12px; height: min-content; justify-content: center; overflow: visible; padding: 16px 32px 16px 32px; position: relative; text-decoration: none; width: min-content; }',
  '.framer-CxqYs .framer-1n0h1yt { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }',
  '.framer-CxqYs .framer-12eesxg, .framer-CxqYs .framer-1czno15 { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 18px); overflow: visible; position: relative; width: 18px; }',
  '.framer-CxqYs .framer-quibww { flex: none; height: auto; position: relative; white-space: pre; width: auto; }',
  '@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-CxqYs.framer-1skui0i, .framer-CxqYs .framer-1n0h1yt { gap: 0px; } .framer-CxqYs.framer-1skui0i > * { margin: 0px; margin-left: calc(12px / 2); margin-right: calc(12px / 2); } .framer-CxqYs.framer-1skui0i > :first-child, .framer-CxqYs .framer-1n0h1yt > :first-child { margin-left: 0px; } .framer-CxqYs.framer-1skui0i > :last-child, .framer-CxqYs .framer-1n0h1yt > :last-child { margin-right: 0px; } .framer-CxqYs .framer-1n0h1yt > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } }',
  '.framer-CxqYs.framer-v-1fbf01a.framer-1skui0i { padding: 8px 16px 8px 16px; }',
  '.framer-CxqYs.framer-v-1fbf01a .framer-1n0h1yt { gap: 4px; }',
  '.framer-CxqYs.framer-v-1fbf01a .framer-1czno15 { height: var(--framer-aspect-ratio-supported, 14px); width: 14px; }',
  '@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-CxqYs.framer-v-1fbf01a .framer-1n0h1yt { gap: 0px; } .framer-CxqYs.framer-v-1fbf01a .framer-1n0h1yt > * { margin: 0px; margin-left: calc(4px / 2); margin-right: calc(4px / 2); } .framer-CxqYs.framer-v-1fbf01a .framer-1n0h1yt > :first-child { margin-left: 0px; } .framer-CxqYs.framer-v-1fbf01a .framer-1n0h1yt > :last-child { margin-right: 0px; } }',
  ...css2,
  ...css,
];
var Framerj1CXYH1vu = withCSS(Component, css3, 'framer-CxqYs',);
var stdin_default = Framerj1CXYH1vu;
Framerj1CXYH1vu.displayName = 'Button';
Framerj1CXYH1vu.defaultProps = { height: 58, width: 209, };
addPropertyControls(Framerj1CXYH1vu, {
  variant: {
    options: ['nvj6GRWIN', 'BM6EYWPbP', 'MHKwcgpiE',],
    optionTitles: ['Primary', 'Secondary', 'Primary Smaller',],
    title: 'Variant',
    type: ControlType.Enum,
  },
  FOdt_Q22J: { defaultValue: 'Get started now', displayTextArea: false, title: 'Title', type: ControlType.String, },
  BQ8KjTU41: {
    __defaultAssetReference:
      'data:framer/asset-reference,QDgxMHJz2vKvFSMvhZpA6xplIBM.svg?originalFilename=nav-arrow-right.svg&preferredSize=auto',
    title: 'Right Icon',
    type: ControlType.ResponsiveImage,
  },
  gT8MXhd7P: {
    __defaultAssetReference:
      'data:framer/asset-reference,QDgxMHJz2vKvFSMvhZpA6xplIBM.svg?originalFilename=nav-arrow-right.svg&preferredSize=auto',
    title: 'Left Icon',
    type: ControlType.ResponsiveImage,
  },
  JRpxGFjwH: { defaultValue: false, title: 'Show Left Icon', type: ControlType.Boolean, },
  wsxRD5u41: { defaultValue: true, title: 'Show Right Icon', type: ControlType.Boolean, },
  Xf0re2Pc_: { title: 'Link', type: ControlType.Link, },
  HihQ7sLzJ: { title: 'Tap', type: ControlType.EventHandler, },
},);
addFonts(Framerj1CXYH1vu, [...fonts2, ...fonts,],);

export { stdin_default, };
